import * as React from 'react';
import PlaylistForm from './PlaylistForm';

function TimeAndDate() {
    return (
      <div className="weddingBody">
          <div className="padded-sides">
              <PlaylistForm></PlaylistForm>
          </div>
      </div>
    );
  } export default TimeAndDate;